import config from '@/@core/app-config/config'

export default {
  // Endpoints
  // loginEndpoint: '/jwt/login',
  // loginEndpoint: 'http://docker.asokhub.xyz:55001/api/TokenAuth/Login',

  loginEndpoint: `${config.webApi}/api/TokenAuth/Login`,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: `${config.webApi}/api/TokenAuth/refreshToken`,
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
