import Vue from 'vue'
import {
  ToastPlugin,
  ModalPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import moment from 'moment'

import config from '@core/app-config/config'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
import '@/libs/acl'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

Vue.prototype.$webApi = config.webApi
Vue.prototype.$hrmsAvatar = config.hrmsAvatar
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.filter('formatDate', value => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
  return new Date()
})

Vue.filter('dateOrNull', value => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
  return '-'
})

Vue.directive('click-outside', {
  bind: (el, binding, vnode) => {
    const element = el
    element.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(element === event.target || element.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', element.clickOutsideEvent)
  },
  unbind: element => document.body.removeEventListener('click', element.clickOutsideEvent),
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
