import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import pages from './routes/pages'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Dashboard/Dashboard.vue'),
      meta: {
        resource: 'acl',
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/detail/:projectId',
      name: 'detail',
      component: () => import('@/views/Detail/ViewDetail.vue'),
      meta: {
        resource: 'acl',
        pageTitle: 'View',
        breadcrumb: [
          {
            text: 'Projects',
            to: { name: 'projects' },
          },
          {
            text: 'Project Detail',
            active: true,
          },
        ],

      },
    },
    {
      path: '/createProject/:projectId',
      name: 'createProject',
      component: () => import('@/views/Detail/ManageDetail.vue'),
      meta: {
        resource: 'acl',
        pageTitle: 'Create',
        breadcrumb: [
          {
            text: 'Projects',
            to: { name: 'projects' },
          },
          {
            text: 'Create Project',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editProject/:projectId',
      name: 'editProject',
      component: () => import('@/views/Detail/ManageDetail.vue'),
      meta: {
        resource: 'acl',
        pageTitle: 'Edit',
        breadcrumb: [
          {
            text: 'Projects',
            to: { name: 'projects' },
          },
          {
            text: 'Project Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        resource: 'Auth',
        redirectIfLoggedIn: true,
        layout: 'full',
      },
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import('@/views/Project/Projects.vue'),
      meta: {
        resource: 'acl',
        pageTitle: 'Projects',
        breadcrumb: [
          {
            text: 'Projects',
            active: true,
          },
        ],
      },
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: () => import('@/views/Project/Checkout.vue'),
      meta: {
        resource: 'acl',
        pageTitle: 'Merge Projects',
        breadcrumb: [
          {
            text: 'Projects',
            to: '/projects',
          },
          {
            text: 'Merge Projects',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user-management',
      name: 'user-management',
      component: () => import('@/views/User/UserManagement.vue'),
      meta: {
        resource: 'user-management',
        pageTitle: 'User',
        breadcrumb: [
          {
            text: 'User',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user-add',
      name: 'user-add',
      component: () => import('@/views/User/Add.vue'),
      meta: {
        resource: 'user-management',
        pageTitle: 'Add User',
        breadcrumb: [
          {
            text: 'User',
            to: '/user-management',
          },
          {
            text: 'Add',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user-detail/:employeeNo',
      name: 'user-detail',
      component: () => import('@/views/User/Detail.vue'),
      meta: {
        resource: 'user-management',
        pageTitle: 'View',
        breadcrumb: [
          {
            text: 'User',
            active: false,
            to: '/user-management',
          },
          {
            text: 'View',
            active: true,
          },
        ],
      },
    },
    {
      path: '/business-type',
      name: 'business-type',
      component: () => import('@/views/BusinessType/BusinessType.vue'),
      meta: {
        resource: 'acl',
      },
    },
    {
      path: '/hashtags',
      name: 'hashtags',
      component: () => import('@/views/Hashtags/Hashtags.vue'),
      meta: {
        resource: 'acl',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'acl',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })
    // If logged in => not authorized
    console.log('If logged in => not authorized')
    return next({ name: 'error-404' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

export default router
